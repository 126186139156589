import React from 'react';
import styled from 'styled-components';
import { Screens } from '../screenSizes';
import { Flex } from '../styles/Shared';

const SectionWrapper = styled.div`
    height: fit-content;
    width: fit-content;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 100%;
    @media (max-width: ${Screens.MOBILE_L}) {
        margin-right: 0;
        margin-left: 0;
    }
`;

const P = styled.p`
    margin-right: 1rem;
    margin-left: 2rem;
    margin-top: 0;
    &:nth-child(2n -1) {
        margin-bottom: 1rem;
    }
    &:only-child {
        margin-bottom: 2rem;
    }
    @media (max-width: ${Screens.MOBILE_L}) {
        margin-right: 0;
        margin-left: 0;
    }
`;

const Header = styled.h2`
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    &:nth-child(2n -1) {
        margin-bottom: 0.25rem;
    }
    @media (max-width: ${Screens.MOBILE_L}) {
        margin-right: 0;
        margin-left: 0;
    }
}`;

const LinkWrapper = styled.a`
    text-decoration: none;
    color: var(--dark-theme-background);
    display: flex;
    font-weight: bold;
    width: fit-content;
    &:hover {
        color: var(--button-hover);
        text-decoration: underline;
    }
`;

export default (props: SectionProps) => {
    return (
        <Flex direction={'column'}>
            <SectionWrapper>
                <Header>
                    {props.link ? (
                        <LinkWrapper href={props.link}>
                            {props.header}
                        </LinkWrapper>
                    ) : (
                        <>{props.header}</>
                    )}
                </Header>
                {props.children}
                <P>{props.body}</P>
            </SectionWrapper>
        </Flex>
    );
};

interface SectionProps {
    header: string;
    body: string;
    link?: string;
    children?: React.ReactNode;
}
