import React from 'react';
import { Helmet } from 'react-helmet';

export default (props: SEOProps) => {
    const { title, description, siteUrl: url } = props.tags;
    const image: string | undefined = props.image;
    const meta:
        | { name?: string; property?: string; content?: string }
        | never[] = [];
    const GA_URL = `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_ID}`;
    return (
        <div>
            <Helmet title="Zach Vaters" />
            <Helmet>
                <meta name="color-scheme" content="dark light" />
                <meta http-equiv="X-UA-Compatible" content="IE=7" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <meta
                    name="keywords"
                    content="Zach|Vaters|blog|projects|software|mun|zachvaters|zachvaters.com"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
            </Helmet>
            {process.env.NODE_ENV !== 'development' && (
                <Helmet>
                    <script async src={GA_URL}></script>
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${process.env.GOOGLE_ANALYTICS_ID}');
                    `}
                    </script>
                </Helmet>
            )}
            <Helmet
                htmlAttributes={{
                    lang: 'en',
                }}
                title={title}
                titleTemplate={title}
                meta={[
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `og:image`,
                        content: image,
                    },
                    {
                        name: `description`,
                        content: description,
                    },
                    {
                        name: `twitter:image`,
                        content: image,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: description,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: '',
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:image:alt`,
                        content: 'Zach Vaters',
                    },
                    {
                        name: `twitter:description`,
                        content: description,
                    },
                    {
                        name: `og:site_name`,
                        content: 'Zach Vaters',
                    },
                    {
                        name: `og:url`,
                        content: 'https://zachvaters.com',
                    },
                    {
                        name: `fb:app_id`,
                        content: '',
                    },
                    {
                        name: `image`,
                        content: image,
                    },
                ].concat(meta)}
            />
        </div>
    );
};

interface SEOProps {
    tags: {
        title: string;
        description: string;
        siteUrl: string;
    };
    image?: string;
}
