import { Link } from 'gatsby';
import React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { Screens } from '../screenSizes';
import { Icon } from '@iconify/react';

const NavBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin-bottom: 1rem;
    width: fit-content;
    text-align: left;
    @media (max-width: ${Screens.MOBILE_L}) {
        flex-direction: row;
        justify-content: center;
        text-align: center;
        width: 100%;
    }
    .active {
        border-bottom: 2px solid white;
    }
`;

const Button = styled.button`
    background-color: transparent;
    border: none;
    font-size: 1rem;
    font-weight: bold;
    color: var(--dark-theme-background);
    cursor: pointer;
    text-align: left;
    &:nth-child(2n) {
    }
    @media (max-width: ${Screens.MOBILE_L}) {
        &:nth-child(2n) {
            margin-left: 1rem;
            margin-right: 1rem;
        }
        & {
            width: 12rem;
            text-align: center;
        }
    }
    &:hover {
        color: var(--button-hover);
    }
    @media (prefers-color-scheme: dark) {
        &:hover {
            border-bottom: 2px solid white;
        }
    }
    @media (prefers-color-scheme: light) {
        &:hover {
            border-bottom: 2px solid black;
        }
    }
`;

type NavState = {
    navItems: NavItem[];
};

interface NavItem {
    copy: string;
    current: boolean;
    setCurrent: React.Dispatch<React.SetStateAction<boolean>>;
}

export default (props: NavState) => {
    const handleClick = useCallback(
        (item: NavItem) => {
            item.setCurrent(true);
            props.navItems.forEach(i => {
                if (i.copy !== item.copy) {
                    i.setCurrent(false);
                }
            }
            );
        },
        []
    );

    return (
        <NavBody>
            {props.navItems.map((item, index) => {
                return (<Button
                    onClick={() => handleClick(item)}
                    className={item.current ? 'active' : ''}
                >
                    {item.copy}
                </Button>);
            })}
        </NavBody>
    );
};
