import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Header from '../components/Header';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { createGlobalStyle } from 'styled-components';
import Section from '../components/Section';
import SEO from '../components/SEO';
import { Screens } from '../screenSizes';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import ProjectsList from '../components/ProjectsList';
import { Flex } from '../styles/Shared';
import DronePhotos from './DronePhotos';

export const GlobalStyle = createGlobalStyle`
    :root {
        --dark-theme-text: #eeeeee;
        --light-theme-text: #000000;
        --button-hover: #023e8a;
        --section-border: #354FFF;
    }
    body {
        padding: 0;
        height: 100vh;
        font-family: 'Source Code Pro',  'Arial', sans-serif;
        margin: 1rem 20rem 1rem 20rem;
    }
    @media (max-width: ${Screens.LAPTOP_L}) {
        body {
            margin: 1rem 5rem 1rem 5rem;
        }
    }
    @media (max-width: ${Screens.LAPTOP}) {
        body {
            margin: 1rem 2rem 1rem 2rem;
        }
    }
    @media (max-width: ${Screens.MOBILE_L}) {
        body {
            margin: 1rem 1rem 1rem 1rem;
        }
    }
`;

const Content = styled.div`
    display: flex;
    width: 95%;
    justify-content: center;
    align-self: center;
    margin: 2.5rem 0rem 0 0rem;
    @media (max-width: ${Screens.MOBILE_L}) {
        flex-direction: column;
    }
`;
const Main = styled.main``;

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    min-width: ${Screens.MOBILE_S};
    min-height: 300px;
`;
const ProfileImg = styled(GatsbyImage)``;

const About = styled.div`
    width: 75%;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
`;

const FlexMediaColum = styled(Flex)`
    @media (max-width: ${Screens.LAPTOP}) {
        flex-direction: column;
    }
`;

const IndexPage = ({ data }: { data: PageQuery }) => {
    const [showAbout, setShowAbout] = useState(true);
    const [showProjects, setShowProjects] = useState(false);
    const [showDronePhotos, setShowDronePhotos] = useState(false);

    const profileImage = getImage(
        data.profileImage.childImageSharp.gatsbyImageData
    );

    const navItems = [
        {
            copy: 'About',
            current: showAbout,
            setCurrent: setShowAbout,
        },
        {
            copy: 'Projects',
            current: showProjects,
            setCurrent: setShowProjects,
        },
        {
            copy:  'Drone Photos',
            current: showDronePhotos,
            setCurrent: setShowDronePhotos,
        },
    ];

    return (
        <Main>
            <GlobalStyle />
            <SEO
                tags={data.site.siteMetadata}
                image={
                    data.profileImage.childImageSharp.gatsbyImageData.images
                        .fallback?.src
                }
            />
            <Header />
            <Content>
                <Nav navItems={navItems} />
                <FlexMediaColum>
                    {showAbout && (
                        <>
                            <Center>
                                <About>
                                    <Section
                                        header={data.dataYaml.header}
                                        body={data.dataYaml.body}
                                    />
                                </About>
                            </Center>
                            {profileImage && (
                                <ImageWrapper>
                                    <Flex
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <ProfileImg
                                            alt={'Profile Image'}
                                            image={profileImage}
                                            imgStyle={{ borderRadius: '100%' }}
                                        />
                                    </Flex>
                                </ImageWrapper>
                            )}
                        </>
                    )}
                    {showProjects && (
                        <ProjectsList project={data.dataYaml.projects} />
                    )}
                    {showDronePhotos && (<DronePhotos />)}
                </FlexMediaColum>
            </Content>
            <Footer />
        </Main>
    );
};

interface PageQuery {
    site: {
        siteMetadata: {
            title: string;
            description: string;
            siteUrl: string;
            image: string;
        };
    };
    profileImage: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
    dataYaml: {
        header: string;
        body: string;
        projects: ProjectsContext;
    };
}

export const pageQuery = graphql`
    query PageQuery {
        site {
            siteMetadata {
                description
                siteUrl
                title
            }
        }
        profileImage: file(relativePath: { eq: "placeholder-image.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 700, height: 550, quality: 100)
            }
        }
        dataYaml {
            header
            body
            projects {
                name
                description
                url
                university
                openSource
                techStack
            }
        }
    }
`;

export default IndexPage;
