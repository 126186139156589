import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Screens, ScreenWidths } from '../screenSizes';
import { useWindowSize } from '../hooks/useWindowSize';
import { Icon } from '@iconify/react';
import { Link } from 'gatsby';
import SnackBar from './SnackBar';

const Bar = styled.div`
    height: 6px;
    @media (max-width: ${Screens.TABLET}) {
        height: 4px;
    }
    }
    @media (max-width: ${Screens.MOBILE_L}) {
        height: 4px;
    }
    @media (max-width: ${Screens.MOBILE_M}) {
        height: 2px;
    }
    @media (max-width: ${Screens.MOBILE_S}) {
        height: 1px;
    }
    @media (prefers-color-scheme: dark) {
        background-color: var(--dark-theme-text);
    }
    @media (prefers-color-scheme: light) {
        background-color: var(--light-theme-text);
    }
`;
const IconWrapper = styled.a`
    align-items: center;
    display: flex;
    @media (prefers-color-scheme: dark) {
        &:hover {
            color: var(--button-hover);
        }
        & {
            color: var(--dark-theme-text);
        }
    }
    @media (prefers-color-scheme: light) {
        &:hover {
            filter: drop-shadow(0 0 0.75rem #000000);
        }
        & {
            color: var(--light-theme-text);
        }
    }
`;

const IconsContainer = styled.div`
    width: fit-content;
    display: flex;
    ${IconWrapper}:nth-child(2) {
        margin-right: 10px;
        margin-left: 10px;
    }
`;

const Title = styled.div`
    width: 100%;
`;

// @ts-ignore
const LinkWrapper = styled(Link)`
    text-decoration: none;
    color: var(--dark-theme-background);
    display: flex;
    gap: 0.5rem;
    align-items: left;
    width: fit-content;
    &:hover {
        color: var(--button-hover);
    }
    @media (prefers-color-scheme: dark) {
        color: var(--light-theme-background);
    }
`;

const Header = styled.div`
    font-weight: bold;
    font-size: 46px;
    @media (max-width: ${Screens.LAPTOP}) {
        font-size: 36px;
    }
    @media (max-width: ${Screens.TABLET}) {
        font-size: 28px;
    }
    @media (max-width: ${Screens.MOBILE_L}) {
        font-size: 24px;
    }
    @media (max-width: ${Screens.MOBILE_M}) {
        font-size: 22px;
    }
    @media (max-width: ${Screens.MOBILE_S}) {
        font-size: 20px;
    }
`;

const HeaderFlex = styled.div`
    display: flex;
    flex-direction: row;
`;

export default () => {
    const [iconSize, setIconSize] = useState<number>(32);
    const size = useWindowSize();
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('foo');

    if (size.width && size.width <= ScreenWidths.MOBILE_L && iconSize !== 24) {
        setIconSize(24);
    } else if (
        size.width &&
        size.width > ScreenWidths.MOBILE_L &&
        iconSize !== 32
    ) {
        setIconSize(32);
    }

    return (
        <>
            <Header>
                <HeaderFlex>
                    <Title>
                        <LinkWrapper to="/">Zach Vaters</LinkWrapper>
                    </Title>
                    <IconsContainer>
                        <IconWrapper
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    'zachvaters@gmail.com'
                                );
                                setShowSnackBar(true);
                                setSnackBarMessage('Email copied to clipboard');
                            }}
                        >
                            <Icon
                                icon="clarity:email-solid"
                                width={iconSize}
                                height={iconSize}
                            />
                        </IconWrapper>
                        <IconWrapper href="https://github.com/zcvaters">
                            <Icon
                                icon="akar-icons:github-fill"
                                width={iconSize}
                                height={iconSize}
                            />
                        </IconWrapper>
                        <IconWrapper href="https://www.linkedin.com/in/zach-vaters-8034b2211">
                            <Icon
                                icon="akar-icons:linkedin-box-fill"
                                width={iconSize}
                                height={iconSize}
                            />
                        </IconWrapper>
                    </IconsContainer>
                </HeaderFlex>
                <Bar />
            </Header>
            {showSnackBar && (
                <SnackBar
                    text={snackBarMessage}
                    setShowSnackBar={setShowSnackBar}
                    showDuration={5000}
                />
            )}
        </>
    );
};
