import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import Section from './Section';
import { Flex } from '../styles/Shared';
import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { Screens } from '../screenSizes';

const PROJECT_STACK_ICON_SIZE = 20;

const mapProjectTechStackToIcon = (languages: string[]) => {
    return languages.map((language: string, index: number) => {
        if (language === 'cpp') {
            return (
                <IconWrapper
                    icon={'bxl:c-plus-plus'}
                    height={PROJECT_STACK_ICON_SIZE}
                    width={PROJECT_STACK_ICON_SIZE}
                    key={`${language}-${index}-${uuidv4()}`}
                />
            );
        } else if (language === 'opengl') {
            return (
                <IconWrapper
                    icon={'file-icons:opengl'}
                    height={PROJECT_STACK_ICON_SIZE}
                    width={PROJECT_STACK_ICON_SIZE}
                    key={`${language}-${index}-${uuidv4()}`}
                />
            );
        } else if (language === 'nodejs') {
            return (
                <IconWrapper
                    icon={'bxl:nodejs'}
                    height={PROJECT_STACK_ICON_SIZE}
                    width={PROJECT_STACK_ICON_SIZE}
                    key={`${language}-${index}-${uuidv4()}`}
                />
            );
        }
        return (
            <IconWrapper
                icon={'cib:' + language}
                height={PROJECT_STACK_ICON_SIZE}
                width={PROJECT_STACK_ICON_SIZE}
                key={`${language}-${index}-${uuidv4()}`}
            />
        );
    });
};

const ProjectList = styled.div`
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
    @media (max-width: ${Screens.MOBILE_L}) {
        flex-direction: column;
    }
`;

const ProjectWrapper = styled.div`
    width: 100%;
    margin-right: 0rem;
    margin-left: 2rem;
    @media (max-width: ${Screens.TABLET}) {
        margin-right: 0;
        margin-left: 0;
    }
`;

const IconWrapper = styled(Icon)`
    margin: 0.25rem;
`;

const Legend = styled.div`
    font-size: 0.75rem;
    margin: 0;
    width: 100%;
    line-height: 2;
    padding: 0.5rem;
    text-align: center;
    @media (max-width: ${Screens.MOBILE_L}) {
        flex-direction: column;
    }
`;

const TechStackIcons = styled.div`
    height: fit-content;
    margin-left: 2rem;
    @media (max-width: ${Screens.TABLET}) {
        margin-right: 0;
        margin-left: 0;
    }
`;

export default (props: ProjectProps) => {
    return (
        <Flex
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <ProjectList>
                {props.project.map((project, index) => {
                    return (
                        <Flex
                            direction={'row'}
                            key={`${project.name}-${index}-${uuidv4()}`}
                        >
                            <ProjectWrapper
                                key={`${project.name}-${index}-${uuidv4()}`}
                            >
                                <Section
                                    header={project.name}
                                    body={project.description}
                                    link={project.url}
                                    key={`${project.name}-${index}-${uuidv4()}`}
                                    children={
                                        <Flex
                                            key={`${
                                                project.name
                                            }-${index}-${uuidv4()}`}
                                            alignItems={'flex-start'}
                                            justifyContent={'flex-start'}
                                        >
                                            <TechStackIcons
                                                key={`${
                                                    project.name
                                                }-${index}-${uuidv4()}`}
                                            >
                                                {project.techStack &&
                                                    mapProjectTechStackToIcon(
                                                        project.techStack
                                                    )}
                                                {project.openSource && (
                                                    <IconWrapper
                                                        icon="akar-icons:github-fill"
                                                        width={
                                                            PROJECT_STACK_ICON_SIZE
                                                        }
                                                        height={
                                                            PROJECT_STACK_ICON_SIZE
                                                        }
                                                        key={`${
                                                            project.name
                                                        }-${index}-${uuidv4()}`}
                                                    />
                                                )}
                                                {project.university && (
                                                    <IconWrapper
                                                        icon="fa-solid:university"
                                                        width={
                                                            PROJECT_STACK_ICON_SIZE
                                                        }
                                                        height={
                                                            PROJECT_STACK_ICON_SIZE
                                                        }
                                                        key={`${
                                                            project.name
                                                        }-${index}-${uuidv4()}`}
                                                    />
                                                )}
                                            </TechStackIcons>
                                        </Flex>
                                    }
                                />
                            </ProjectWrapper>
                        </Flex>
                    );
                })}
                <Flex justifyContent={'center'} alignItems={'center'}>
                    <Legend>
                        Projects Icon Legend:{' '}
                        <IconWrapper
                            icon="akar-icons:github-fill"
                            width={18}
                            height={18}
                        />
                        === Open Source{', '}
                        <IconWrapper
                            icon="fa-solid:university"
                            width={18}
                            height={18}
                        />{' '}
                        === Project through University degree
                    </Legend>
                </Flex>
            </ProjectList>
        </Flex>
    );
};

interface ProjectProps {
    project: ProjectsContext;
}
