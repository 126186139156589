import { Icon } from '@iconify/react';
import React from 'react';
import styled from 'styled-components';
import { Flex } from '../styles/Shared';

const Footer = styled.footer`
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    gap: 0.5rem;
    @media (prefers-color-scheme: dark) {
        & {
            color: var(--dark-theme-background);
    }
    @media (prefers-color-scheme: light) {
        & {
            color: var(--light-theme-background);
        }
    
`;

const HyperLink = styled.a`
    text-decoration: none;
    color: var(--light-theme-text);
    @media (prefers-color-scheme: dark) {
        color: var(--dark-theme-text);
    }
    &:hover {
        text-decoration: underline;
        color: var(--button-hover);
    }
`;

const Wrapper = styled.div`
    margin-bottom: 0.5rem;
`;

export default () => {
    return (
        <Flex
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Footer>
                <Wrapper>
                    <Icon
                        icon="ant-design:copyright-circle-filled"
                        width={8}
                        height={8}
                        style={{ marginRight: '0.5rem' }}
                    />
                    2022-{new Date().getFullYear()} Zach Vaters
                </Wrapper>
                <Wrapper>
                    <HyperLink href="https://github.com/zcvaters/zcvaters">
                        Source Code&nbsp;
                        <Icon
                            icon="akar-icons:github-fill"
                            width={14}
                            height={14}
                        />
                    </HyperLink>
                </Wrapper>
            </Footer>
        </Flex>
    );
};
